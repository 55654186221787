<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name" :current="record.name"/>
    <v-row>
      <v-col>
        <base-tab-panel
            :module_name="page.name"
            :record="record"
            @change="save"
            @archive="archive"
            :loading="loading"
            :model="page.model">
          <template v-slot:main_fields>
            <div style="width: 100%" class="text-center">
              <v-file-input
                  v-if="!record.file"
                  accept="image/*"
                  :label="$store.getters.translate('logo')"
                  show-size
                  @change="saveFile"
                  v-model="file"/>
              <v-layout v-else align-center justify-center column fill-height>
                <v-flex row align-center>
                  <v-img position="center" max-width="200" contain :src="record.file.full_path"/>
                </v-flex>
                <v-btn text color="red" class="mt-2" @click="deleteFile">
                  <v-icon color="error">mdi-trash-can</v-icon>
                </v-btn>
              </v-layout>
            </div>
            <template v-for="field in usual_fields">
              <base-field
                  :key="field.name"
                  :value="record[field.name]"
                  :field="field"
                  @change="usualFieldDataChanged"/>
            </template>
            <base-tags v-if="tags_required" v-model="record.tags" :module_name="page.name"/>
          </template>
          <template v-slot:module_fields>
            <template v-for="field in custom_fields">
              <base-field
                  :key="field.name"
                  :value="record.custom_fields[field.name]"
                  :field="field"
                  @change="customFieldDataChanged"/>
            </template>
            <email-addresses v-model="record.emailaddresses" email_type="personal" label="emailaddress" @change="save"/>
            <v-row>
              <v-col>
                <v-text-field
                    class="ma-0"
                    :label="$store.getters.translate('ascription')"
                    v-model="record.ascription"
                    validate-on-blur/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <base-addresses v-model="record.addresses" @change="save" address_type="visiting" label="contact_address" mapID="map1"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-switch class="mt-0" :label="$store.getters.translate('billing_address_the_same')" v-model="hide_billing_address"/>
              </v-col>
            </v-row>
            <v-row v-if="!hide_billing_address">
              <v-col>
                <base-addresses v-model="record.addresses" @change="save" address_type="billing" label="billing_address" mapID="map2"/>
              </v-col>
            </v-row>
          </template>
        </base-tab-panel>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-expansion-panels v-model="panel" multiple>
          <base-table-includable ref="contacts_table" :page="page" :parent_module_id="record.id" relation_name="contacts" @create="createContact" @edit="editContact" @change="load"/>
          <base-table-includable ref="workers_table" :page="page" :parent_module_id="record.id" relation_name="workers" @edit="editWorker" @change="load"/>
          <base-table-includable ref="notes_table" :page="page" :parent_module_id="record.id" relation_name="notes" @create="createNote" @edit="editNote" @change="load"/>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <create-person modal_name="create_person" action="create_contact" ref="create_person" user_type="contact" @refresh="load"/>
    <base-create-dialog modal_name="create_note" ref="create_note" :page="notes_page" :files_required="true" :multiple_files="true" @refresh="load"/>
    <base-edit-dialog modal_name="edit_note" ref="edit_note" :page="notes_page" :files_required="true" :multiple_files="true" @refresh="load"/>
  </v-container>
</template>

<script>
import BaseTableIncludable from "../../components/commonComponents/BaseTableIncludableData";
import BaseAddresses from "../../components/commonComponents/BaseAddresses";
import BaseTabPanel from "../../components/commonComponents/BaseTabPanel";
import CreatePerson from "../people/Create";
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import helpFunctions from "../../plugins/helpFunctions";
import BaseEditDialog from "../../components/commonComponents/BaseEditDialog.vue";
import BaseCreateDialog from "../../components/commonComponents/BaseCreateDialog.vue";

export default {
  components: {
    BaseCreateDialog,
    BaseEditDialog,
    BaseTableIncludable,
    BaseAddresses,
    BaseTabPanel,
    CreatePerson,
    BaseBreadcrumb,
  },
  data() {
    return {
      page: helpFunctions.pages.companies,
      notes_page: helpFunctions.pages.notes,
      record: {},
      usual_fields: [],
      custom_fields: [],
      tags_required: false,
      loading: false,
      file: null,
      hide_billing_address: false,
    };
  },
  created() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      this.record = await helpFunctions.loadModel(this.$toasted, this.page.name, this.$route.params.id);
      this.record.addresses.forEach((address) => {
        if (address.primary) {
          this.hide_billing_address = true;
        }
      });
      this.updateIncludableTables();
      await this.getFields();
    },
    updateIncludableTables() {
      this.$refs.workers_table.records = this.record.workers;
      this.$refs.contacts_table.records = this.record.contacts;
      this.$refs.notes_table.records = this.record.notes;
      this.$refs.workers_table.selected = [];
      this.$refs.contacts_table.selected = [];
      this.$refs.notes_table.selected = [];
    },
    async save() {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toasted, this.record, this.usual_fields)) {
          if (this.record.phonenumber && this.record.phonenumber.charAt(0) !== '+') {
            this.$toasted.error(this.$store.getters.translate("international_number_error"));
          } else {
            this.loading = true;
            if(await helpFunctions.saveModel(this.$toasted, this.page.name, this.record)) {
              await this.load();
            }
            this.loading = false;
          }
        }
      }
    },
    async archive() {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toasted, this.page.name, this.record.id)) {
            await this.$router.push("/" + this.page.name);
          }
          this.loading = false;
        }
      });
    },
    createNote() {
      this.$refs.create_note.load();
      this.$refs.create_note.record.parent_model = 'Company';
      this.$refs.create_note.record.parent_model_id = this.record.id;
    },
    editNote(item) {
      this.$refs.edit_note.load(item.id);
    },
    createContact() {
      this.$refs.create_person.openModal('create_person');
      this.$refs.create_person.record.add_to_companies = [this.record.id];
    },
    editContact(item) {
      this.$router.push("/contacts/" + item.id);
    },
    editWorker(item) {
      this.$router.push("/workers/" + item.id);
    },
    async saveFile() {
      if(!this.loading && this.file) {
        this.loading = true;
        const formData = new FormData();
        formData.append("attachment", this.file);
        formData.append("company_id", this.record.id);
        await helpFunctions.saveFile(this.$toasted, this.page.name, formData);
        await this.load();
        this.loading = false;
      }
    },
    deleteFile() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          await helpFunctions.deleteFile(this.$toasted, this.page.name, {company_id: this.record.id});
          await this.load();
          this.file = null;
          this.loading = false;
        }
      });
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    customFieldDataChanged(data) {
      this.record.custom_fields[data[0]] = data[1];
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_update");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.custom_fields = response.custom_fields;
      this.loading = false;
    },
  },
  computed: {
    panel: {
      set(value) {
        let payload = {
          module_name: this.page.name,
          value: value,
        };
        this.$store.dispatch("setPanel", payload);
      },
      get() {
        return this.$store.getters.getPanel(this.page.name);
      },
    },
  },
  watch: {
    hide_billing_address: {
      handler() {
        if (this.hide_billing_address) {
          this.record.addresses.forEach((address) => {
            if (address.type === 'billing') {
              this.$http
                  .delete(this.$store.getters.appUrl + "v2/addresses/" + address.id)
                  .then(() => {
                    this.record.addresses = this.record.addresses.filter((address2) => address2.id !== address.id);
                  })
                  .catch((error) => {
                    this.$toasted.error(error);
                    this.loading = false;
                  });
            }
            else if(address.type === 'visiting' && !address.primary) {
              address.primary = 1;
              this.$http
                  .patch(this.$store.getters.appUrl + "v2/addresses/" + address.id, address)
                  .then(() => {
                    this.$toasted.success(this.$store.getters.translate("success"));
                  })
                  .catch((error) => {
                    this.$toasted.error(error);
                    this.loading = false;
                  });
            }
          });
        }
        else {
          this.record.addresses.forEach((address) => {
            if (address.type === 'visiting' && address.primary) {
              address.primary = 0;
              this.$http.patch(this.$store.getters.appUrl + "v2/addresses/" + address.id, address);
            }
          });
        }
      },
    },
  },
};
</script>